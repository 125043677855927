//方法一
/* 需传参，n为是否必填参数 */
export const isPhoneRule = (n) => {
  return [
    {
      required: n,
      message: "不能为空",
      trigger: "blur",
    },
    {
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请输入正确电话号码",
    },
  ];
};

//方法二
/* 不需要传参，直接使用即可 */
export const isPhone = (rule, value, callback) => {
  let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (value != "") {
    if (reg.test(value)) {
      callback();
    } else {
      callback("请输入正确联系方式");
    }
  } else {
    callback();
  }
};

/* 打乱数组顺序 */
export function shuffle(arr) {
  for (let i = 0; i < arr.length; i++) {
    const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i;
    [arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
  }
  return arr;
}

/*  */
export function downloadVideo(url, name) {
  if (url) {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        // fetch('/download/' + url).then(res => res.blob()).then(blob => {
        console.log("download:" + name);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name + ".mp4"; //视频下载后的名称
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return true;
      });
  }
}
/*  */
export function download(url, name) {
  if (url) {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        console.log("download:" + name);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name; //视频下载后的名称
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return true;
      });
  }
}

//时间戳转换方法    date:时间戳数字
export function formatDate(date1) {
  date1 = date1 + "";
  if (date1.length == 10) {
    date1 = date1 * 1000;
  }
  var date = new Date(date1);
  var YY = date.getFullYear() + "-";
  var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + " " + hh + mm + ss;
}
export function formatDay(date1) {
  date1 = date1 + "";
  if (date1.length == 10) {
    date1 = date1 * 1000;
  }
  var date = new Date(date1);
  var YY = date.getFullYear() + "-";
  var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return YY + MM + DD;
}

//时间戳转换方法    date:时间戳数字
export function randomText() {
  //生成10位i随机数
  var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  var arr = str.split("");
  var result = "";
  for (var i = 0; i < 10; i++) {
    var n = Math.floor(Math.random() * arr.length);
    result += arr[n];
  }
  return result;
}

// Base64 转 File
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","); //分割为数组，分割到第一个逗号
  let mime = arr[0].match(/:(.*?);/)[1]; //获取分割后的base64前缀中的类型
  let bstr = window.atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "jpg" });
}

//
export function countAndReplace(inputString) {
  var pattern = /⏱+/g;

  var replacedString = inputString.replace(pattern, function (match) {
    return `<${match.length / 2}s>`;
  });

  console.log("Output string: " + replacedString);
  return replacedString;
}
